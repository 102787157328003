<template>
	<Modal
		class="todo-workbench-modal"
		:value="visible"
		width="1200"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		:footer-hide="true"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>待办列表</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<div class="task-query" v-if="type !== 'project'">
			<span>任务名称：</span>
			<div class="task-input-wrapper">
				<Input
					style="width: auto"
					search
					v-model="taskNameLike"
					enter-button
					placeholder="请输入任务名称"
					@on-search="taskChange"
				/>
			</div>
		</div>
		<CtmsDataGrid
			:height="gridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<!-- 流程审批-->
		<Approval
			:visible="approvalVisible"
			:projectId="projectId"
			@onOk="handleOk"
			@onCancel="handleCancel"
			:procInstId="procInstId"
			:procInstName="procInstName"
			:taskId="taskId"
			:type="type === 'projectProcess' ? 'view' : 'toDo'"
			:toDoId="toDoId"
		></Approval>
		<RegisterApproval
			:visible="registerVisible"
			@onOk="handleRegisterOk"
			@onCancel="registerVisible = false"
			:id="registerId"
		></RegisterApproval>
		<!-- 伦理上会投票 -->
		<ethical-vote
			:visible="voteVisible"
			:toDoId="toDoId"
			@finishVote="finishVote"
			@onCancel="cancelVote"
		>
		</ethical-vote>
	</Modal>
</template>

<script>
import api from "@/api/workbench/workbench"
import projectApi from "@/api/project/project"
import { mapState } from "vuex"
import Approval from "@approval"
import RegisterApproval from "../RegisterApproval.vue"
import EthicalVote from "./EthicalVote.vue"

const { getToDoList } = api
const { getProjectTodo, getProjectProcess } = projectApi

export default {
	name: "ToDoList",
	components: {
		Approval,
		RegisterApproval,
		EthicalVote
	},
	props: ["visible", "type", "proId"],
	data() {
		return {
			loading: false,
			fullscreen: true,
			taskId: "",
			listData: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					width: 300,
					nowrap: true
				},
				{
					title: "主要研究者(PI)",
					key: "piName"
				},
				{
					title: "流程名称",
					key: "procInstName"
				},
				{
					title: "待办创建时间",
					key: "createdTime",
					width: 180
				},
				{
					title: "任务名称",
					key: "taskName"
				},
				{
					title: "操作",
					key: "action",
					fixed: "right",
					width: 160,
					renderButton: ({ row }) => {
						const actionArr = [
							{
								label: "详情",
								on: {
									click: this.handleApprovalShow
								}
							},
							{
								label: "注册审批",
								on: {
									click: this.handleRegisterShow
								}
							}
						]
						if (this.type === "projectProcess") {
							// 查看正在运行中的流程
							actionArr[1] = null
						} else {
							// 流程审批
							if (row.taskType === "UserTask") {
								actionArr[1] = null
							} else if (row.taskType === "UserAudit") {
								// 注册审批
								actionArr[0] = null
							}
							if (row.vote) {
								// 伦理投票
								actionArr.push({
									label: "伦理投票",
									on: {
										click: this.handleEthicalVote
									}
								})
							}
						}

						return actionArr.filter(item => !!item)
					}
				}
			],
			viewVisible: false,
			approvalVisible: false,
			toDoId: "",
			procInstId: "",
			procInstName: "",
			projectId: "",
			registerVisible: false,
			registerId: "",
			taskNameLike: "",
			// 伦理投票变量 start
			voteVisible: false, // 是否 显示 伦理投票 弹窗
			voteFinish: false, // 伦理投票 是否结束
			voteStatus: "" // 投票状态：进行中=1；已结束=2；已取消=3；
			//  伦理投票 end
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initList()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 150
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 130
				: this.dataGridHeight
		},
		projId() {
			return this.proId || this.$route.params.projectId
		},
		isShenzhen() {
			return this.$store.state.environment === "shenzhenrenmin"
		}
	},
	created() {
		if (this.isShenzhen) {
			this.columns.splice(1, 0, {
				title: "项目编号",
				key: "projectApproveNo"
			})
			this.columns.splice(
				3,
				0,
				{
					title: "申办方",
					key: "sponsorName"
				},
				{
					title: "CRO",
					key: "croName"
				}
			)
			this.columns = [...this.columns]
		}
	},
	methods: {
		// 初始化列表
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				// 根据type确定请求的接口
				// all:所有待办
				// project:项目内待办
				let res = false
				switch (this.type) {
					case "all":
						res = await getToDoList({
							pageNum: this.page.current,
							pageSize: this.page.pageSize,
							status: 1,
							taskNameLike: this.taskNameLike
						})
						break
					case "project":
						res = await getProjectTodo({
							id: this.projId,
							pageNum: this.page.current,
							pageSize: this.page.pageSize,
							status: 1
						})
						break
					case "projectProcess":
						res = await getProjectProcess(this.projId, {
							pageNum: this.page.current,
							pageSize: this.page.pageSize,
							taskNameFuzzy: this.taskNameLike,
							status: 1
						})
						break
					default:
						res = await getToDoList({
							pageNum: this.page.current,
							pageSize: this.page.pageSize,
							status: 1,
							taskNameLike: this.taskNameLike
						})
						break
				}
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 伦理投票
		handleEthicalVote({ row }) {
			this.toDoId = row.id
			this.procInstId = row.procInstId
			this.procInstName = row.procInstName
			this.projectId = row.projectId
			this.$nextTick(() => {
				this.voteVisible = true
			})
		},
		// 完成伦理投票
		finishVote() {
			this.voteVisible = false
			this.voteFinish = true
			this.initList()
		},
		cancelVote() {
			this.voteVisible = false
		},
		// 查看代办详情
		handleApprovalShow({ row }) {
			this.toDoId = row.id
			this.procInstId = row.procInstId
			this.procInstName = row.procInstName
			this.projectId = row.projectId
			this.taskId = row.taskId
			this.$nextTick(() => {
				this.approvalVisible = true
			})
		},
		// 查看流程图显示
		handleViewShow({ row }) {
			this.viewVisible = true
			this.procInstId = row.procInstId
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
			// this.fullscreen = false;
		},
		// 审批提交成功
		handleOk() {
			this.approvalVisible = false
			this.page.current = 1
			this.$emit("onOk")
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 注册审批成功
		handleRegisterOk() {
			this.registerVisible = false
			this.page.current = 1
			this.$emit("onOk")
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 显示注册审批
		handleRegisterShow({ row }) {
			this.registerVisible = true
			this.registerId = row.taskId
		},
		// 审批关闭
		handleCancel() {
			this.procInstId = ""
			this.procInstName = ""
			this.toDoId = ""
			this.approvalVisible = false
		},
		taskChange(v) {
			this.page.current = 1
			this.initList()
		}
	}
}
</script>

<style lang="less">
.todo-workbench-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.task-query {
		margin-bottom: 15px;
		display: flex;
		> span {
			font-size: 14px;
			line-height: 32px;
			display: inline-block;
		}
		.task-input-wrapper {
			display: inline-block;
			width: 300px;
		}
	}
}
</style>
