<template>
	<Modal
		:value="visible"
		title="查看原图"
		@on-cancel="closedHandle"
		:width="700"
	>
		<div slot="footer">
			<Button @click="closedHandle">关闭</Button>
		</div>
		<div class="originImg" v-if="visible">
			<img
				v-if="images.length <= 1"
				:src="getPreviewImg(images[0])"
				alt=""
				style="max-width: 668px"
			/>
			<Carousel v-else :value="currentIndex" radius-dot>
				<CarouselItem
					v-for="(image, index) in images"
					:key="index"
					style="width: 668px"
				>
					<img :src="getPreviewImg(image)" alt="" style="max-width: 100%" />
				</CarouselItem>
			</Carousel>
		</div>
	</Modal>
</template>
<script>
export default {
	props: ["visible", "images", "selIndex"],
	data() {
		return {
			currentIndex: 0
		}
	},
	watch: {
		visible(v) {
			if (v) {
				this.currentIndex = this.selIndex
			}
		}
	},
	methods: {
		closedHandle() {
			this.$emit("closed")
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		}
	}
}
</script>
<style lang="less">
.originImg {
	width: 100%;
	text-align: center;
}

.originImg .ivu-carousel-dots li button {
	background: #2d8cf0;
}
</style>
